<template>
  <div class="content md">
    <div class="find-name">
      <a-space>
        <a-input placeholder="请输入企业微信id" allowClear v-model="wxId" style="width: 300px" @keyup.enter="find" />
        <a-button type="primary" @click="find">查找</a-button>
      </a-space>
      <a-space style="margin-top: 16px">
        <span>结果：</span>
        <open-data type="userName" :openid="openid" v-if="openid"></open-data>
      </a-space>
    </div>
  </div>
</template>

<script>

    export default {
        name: "FindName",
        data() {
            return {
                wxId: "",
                openid: ""
            }
        },
        methods: {
            find() {
                if(this.wxId) {
                    this.openid = this.wxId
                }
            }
        }
    }
</script>

<style lang="less">
.find-name {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 48px 0;
  width: 480px;
}
</style>
